<template>
  <div v-loading="loading">
    <div ref="screen" class="input">
      <!--    顶部选项按钮 开始-->
      <el-row>
        <el-button type="primary" @click="screenReceivables" size="mini" v-if="authe.Hotel_Finance_BookManage_BatchSettlementPosting">批量结清挂账</el-button>
          <el-dialog title="批量结清挂账" :visible.sync="receivables.dialogScreenReceivables" append-to-body :close-on-click-modal="false" width="400px" style="text-align: center">
            <el-button @click="putReceivables(0,true)" type="success">现金收银</el-button>
            <el-button @click="putReceivables(1,true)" type="success">扫码收银</el-button>
            <el-button @click="putReceivables(2,true)" type="success">台卡收银</el-button>
          </el-dialog>
      </el-row>
      <!--    顶部选项按钮 结束-->
      <!--    筛选框 开始-->
      <el-row class="screen">
        <el-form inline :label-width="screenLabelWidth">
          <el-row>
            <el-form-item label="渠道类型">
              <el-select v-model="data.params.jsrrChannelId" filterable @change="searchChange" clearable size="mini" class="screenInput">
                <el-option v-for="(value,i) in jcuGroupType" :key="i" :label="value.jcuGroupType" :value="value.jcuId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="结清挂账">
              <el-select v-model="data.params.jsrcClearCredit" filterable @change="searchChange" clearable size="mini" class="screenInput">
                <el-option label="是" :value="0"></el-option>
                <el-option label="否" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
        </el-form>
      </el-row>
      <!--    筛选框 结束-->
    <!--    详细信息弹框 开始-->
      <el-dialog title="订单详情" :visible.sync="details.dialogDetails" @close="closeDialogDetails" :close-on-click-modal="false" width="680px" class="screen">
        <el-form inline :label-width="screenLabelWidth">
          <el-row>
            <el-form-item label="入住客房">
              <el-input v-model="details.params.address" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="客房类型">
              <el-input v-model="details.params.hsRoomType" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="客户姓名">
              <el-input v-model="details.params.popName" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="日均单价">
              <el-input v-model="details.params.jsrcDailyPrice" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="总住天数">
              <el-input v-model="details.params.jsrcTotalDays" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="总住金额">
              <el-input v-model="details.params.jsrcTotalPrice" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
          </el-row>
          <el-row v-for="(value,i) in details.params.popTmpList" :key="i">
            <el-form-item label="住户姓名">
              <el-input v-model="value.popName" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="住户手机">
              <el-input v-model="value.popTelephone" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="住户身份">
              <el-input v-model="value.popIdcard" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="客房押金">
              <el-input v-model="details.params.jsrcDeposit" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="入住时间">
              <el-input v-model="details.params.jsrcBeginTime" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="退房时间">
              <el-input v-model="details.params.jsrcEndTime" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="订单状态">
              <el-input v-model="details.params.jsrcState" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="入住类型">
              <el-input v-model="details.params.jsrcTypeOccupancy" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item style="margin-left: 15px">
              <el-button @click="details.consumption.dialogConsumption = true" size="mini" v-if="authe.Hotel_Finance_BookManage_ExpensesRecord">消费记录</el-button>
            </el-form-item>
          </el-row>
        </el-form>
        <el-table 
        :data="details.followUp.dataFollowUp"
        highlight-current-row 
        @current-change="followUpCurrentChange"
        @row-dblclick="details.followUp.dialogFollowUpDetails = true"
        height="216px" 
        size="mini"
        style="width=100%;" >
          <el-table-column prop="registrantName" label="操作人" min-width="25px"></el-table-column>
          <el-table-column prop="type" label="类型" width="80px"></el-table-column>
          <el-table-column prop="text" show-overflow-tooltip label="操作记录" min-width="100px"></el-table-column>
          <el-table-column prop="time" label="操作时间" width="150px"></el-table-column>
        </el-table>
        <!--    消费记录详情弹窗 开始-->
        <el-dialog title="消费记录详情" :visible.sync="details.consumption.dialogConsumption" append-to-body :close-on-click-modal="false" width="460px" class="screen">
          <el-form inline :label-width="screenLabelWidth">
            <el-form-item label="总金额">
              <el-input v-model="details.consumption.amount" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
          </el-form>
          <el-table 
          :data="details.consumption.dataConsumption"
          highlight-current-row 
          @row-dblclick="details.followUp.dialogFollowUpDetails = true"
          height="216px" 
          size="mini"
          style="width=100%;" >
            <el-table-column prop="popservice" label="服务类型" min-width="100px"></el-table-column>
            <el-table-column prop="popcharge" label="服务金额" min-width="100px"></el-table-column>
          </el-table>
        </el-dialog>
        <!--    消费记录详情弹窗 结束-->
        <!--    跟进详细信息弹窗 开始-->
        <el-dialog title="跟进详细信息" :visible.sync="details.followUp.dialogFollowUpDetails" append-to-body :close-on-click-modal="false" width="460px" class="screen">
          <el-form inline :label-width="screenLabelWidth">
            <el-row>
              <el-form-item label="操作时间">
                <el-input v-model="details.followUp.rowData.time" disabled size="mini" style="width:335px"></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="操作人">
                <el-input v-model="details.followUp.rowData.registrantName" disabled size="mini" class="screenInput"></el-input>
              </el-form-item>
              <el-form-item label="类型">
                <el-input v-model="details.followUp.rowData.type" disabled size="mini" class="screenInput"></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="操作记录">
                <el-input v-model="details.followUp.rowData.text" disabled type="textarea" rows="4" size="mini" style="width:335px"></el-input>
              </el-form-item>
            </el-row>
          </el-form>
        </el-dialog>
        <!--    跟进详细信息弹窗 结束-->
        <div slot="footer" style="text-align: center">
          <el-row style="margin-bottom: 10px" v-if="authe.Hotel_Finance_BookManage_SingleSettlementPosting">
            <el-button @click="putReceivables(0,false)" type="success">现金收银</el-button>
            <el-button @click="putReceivables(1,false)" type="success">扫码收银</el-button>
            <el-button @click="putReceivables(2,false)" type="success">台卡收银</el-button>
          </el-row>
          <el-row>
            <el-button @click="changeRowData(false)">上一条</el-button>
            <el-button @click="details.dialogDetails = false">取 消</el-button>
            <el-button @click="changeRowData(true)">下一条</el-button>
          </el-row>
        </div>
      </el-dialog>
    <!--    详细信息弹框 结束-->
    <!--    收银弹框 开始-->
      <el-dialog :title="receivables.title" :visible.sync="receivables.dialogReceivables" @close="cleanReceivables" append-to-body :close-on-click-modal="false" width="400px">
        <el-form :label-width="screenLabelWidth">
          <el-form-item label="应收">
            <el-input v-model="receivables.receivable" :readonly="receivables.state == 2" :disabled="receivables.state == 0 || receivables.state == 1" @keyup.enter.native="putReceivablesSave(2)" ref="receivable" onkeypress="if(event.keyCode == 13) return false;" style="width:275px"></el-input>
          </el-form-item>
          <el-form-item label="付款码" v-if="receivables.state == 1">
            <el-input v-model="receivables.paymentCode" @keyup.enter.native="putReceivablesSave(1)" ref="paymentCode" onkeypress="if(event.keyCode == 13) return false;" oninput="value=value.replace(/[^\d]/g,'')" style="width:275px"></el-input>
          </el-form-item>
          <el-form-item label="实收" v-if="receivables.state == 0">
            <el-input v-model="receivables.netReceipts" @input="netReceiptsChange" @keyup.enter.native="putReceivablesSave(0)" ref="netReceipts" oninput="if(isNaN(value)) { value = value.replace(/[^\d^\.]/g,'') } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)} if(value.indexOf('.',value.indexOf('.')+1)!=-1){value=value.slice(0,value.indexOf('.')+1)}" style="width:275px"></el-input>
          </el-form-item>
          <el-alert
            v-show="receivables.dialogAlert"
            style="margin-bottom: 20px"
            title="警告"
            type="warning"
            description="当前为“现金收银”模式，请检查收款金额是否正确。如需进入“扫码收银”模式请按F9。"
            show-icon>
          </el-alert>
          <el-form-item label="找零" v-if="receivables.state == 0">
            <el-input v-model="receivables.giveChange" disabled style="width:275px"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" style="text-align:center">
          <el-button v-if="receivables.state == 0" @click="putReceivablesSave(0)" type="success">现金收银</el-button>
          <el-button v-if="receivables.state == 1" @click="putReceivablesSave(1)" type="success">扫码收银</el-button>
          <el-button v-if="receivables.state == 2" @click="putReceivablesSave(2)" type="success">台卡收银</el-button>
        </div>
      </el-dialog>
    <!--    收银弹框 结束-->

    </div>
    <!--    数据列表 开始-->
    <div style="text-align:center;">
      <!--    表格显示列表 开始-->
      <el-table 
      :data="dataList" 
      ref="highLightRow"
      highlight-current-row 
      @current-change="currentChange"
      @row-dblclick="orderDetails"
      @selection-change="selectionChange"
      :height="tableHeight" 
      style="width:100%;">
        <el-table-column type="selection" width="45"></el-table-column>
        <el-table-column prop="jcuGroupType" label="协议单位" min-width="50px"></el-table-column>
        <el-table-column prop="address" label="地址" :formatter="formatAddress" show-overflow-tooltip min-width="100px"></el-table-column>
        <el-table-column prop="popName" label="客户姓名" min-width="45px"></el-table-column>
        <el-table-column prop="jsrcTotalDays" label="总天数" min-width="35px"></el-table-column>
        <!-- <el-table-column prop="jsrcAmountPayable" label="房费" show-overflow-tooltip min-width="40px"></el-table-column>
        <el-table-column prop="jsrcDeposit" label="押金" min-width="30px"></el-table-column>
        <el-table-column prop="jsrcTotalPrice" label="总价" show-overflow-tooltip min-width="40px"></el-table-column> -->
        <el-table-column prop="jsrcArrears" label="欠费总额" width="155px"></el-table-column>
        <el-table-column prop="jsrcBeginTime" label="入住时间" width="155px"></el-table-column>
        <el-table-column prop="jsrcEndTime" label="退房时间" width="155px"></el-table-column>
        <el-table-column prop="jsrcState" label="状态" min-width="30px"></el-table-column>
        <el-table-column prop="suStaffName" label="登记人" show-overflow-tooltip min-width="40px"></el-table-column>
        <el-table-column prop="jsrcRegistrationTime" label="登记时间" width="155px"></el-table-column>
      </el-table>
      <!--    表格显示列表 结束-->

      <!--    分页 开始-->
      <el-pagination
        background layout="total, sizes, prev, pager, next, jumper" 
        :page-count="data.pages"
        :page-size="data.params.limit" 
        :total="data.total" 
        :current-page="data.params.cursor" 
        @current-change="handleCurrentChange" 
        @size-change="handleSizeChange" 
        :page-sizes="[5,10,20,40]"
      ></el-pagination>
      <!--    分页 结束-->
    </div>
    <!--    数据列表 结束-->
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'settleAccounts',
  data(){
    return{
      loading: false,
      screenState: false,
      screenLabelWidth: '75px',
      tableHeight: window.innerHeight - 210,
      details: {
        dialogDetails: false,
        params: {
          address: '',//入住客房
          hsRoomType: '',//客房类型
          popName: '',//客户姓名
          jsrcDailyPrice: '',//日均单价
          jsrcTotalDays: '',//总住天数
          jsrcTotalPrice: '',//总住金额
          popTmpList: '',//同住人信息
          jsrcDeposit: '',//客房押金
          jsrcBeginTime: '',//入住时间
          jsrcEndTime: '',//退房时间
          jsrcState: '',//订单状态
          jsrcTypeOccupancy: '',//入住类型
        },
        consumption: {
          dialogConsumption: false,
          amount: 0,
          dataConsumption: [],
        },
        followUp: {
          dialogFollowUpDetails: false,
          rowData: {},
          dataFollowUp: [],
        },
      },
      receivables: {
        dialogReceivables: false,
        bool: false,
        dialogScreenReceivables: false,
        title: '',
        state: '',
        receivable: '',//应收
        paymentCode: '',//付款码
        netReceipts: '',//实收
        giveChange: '',//找零

        dialogAlert: false,
      },
      data: {//数据分页查询
        params: {
          jsrrChannelId: '',//渠道类型
          jsrcClearCredit: 1,//结清挂账
          limit: 10,//每页的条数
          cursor: 1,//初始页
        },
        total: 0,//总条数
        pages: 0,//每页数据
      },
      dataList: [],
      dataArray: [],
      dataArrayId: [],
      rowData: null,
    }
  },
  computed: {
    ...mapState(['jcuGroupType','authe'])
  },
  created(){
    this.getDataList()
  },
  mounted(){
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - this.$refs.screen.clientHeight - 140
    })
  },
  methods: {
  //查询数据
    getDataList(){//查询数据
      this.$axios({
        method: 'get',
        url: '/hotel/shortRentContract/getOnCreditOrder',
        params: this.data.params
      }).then(res => {
        if (res.data.result.records.length > 0) {
          this.dataList = res.data.result.records
          this.data.total = res.data.result.total
          this.rowData = null
        } else {
          this.dataList = []
          this.data.total = 0
          this.rowData = null
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    searchChange(){//筛选条件改变时
      this.data.params.cursor = 1
      this.getDataList()
    },
    dateChange(){//日期改变时查询
      if(this.data.time == null) {
        this.data.params.jsrcBeginTime = ''
        this.data.params.jsrcEndTime = ''
        this.data.params.cursor = 1
        this.getDataList()
      }else{
        this.data.params.jsrcBeginTime = `${this.data.time[0]} 00:00:00`
        this.data.params.jsrcEndTime = `${this.data.time[1]} 00:00:00`
        this.data.params.cursor = 1
        this.getDataList()
      }
    },
    formatAddress(row, column) {
      let a = row.hsAddCommunity ? `${row.hsAddCommunity}\u3000` : ''
      let b = row.hsRoomType ? `${row.hsRoomType}\u3000` : ''
      let c = row.hsAddDoorplateno ? `${row.hsAddDoorplateno}` : ''
      return a + b + c
    },
    handleCurrentChange(cursor){//点击更改点前页数
      this.data.params.cursor = cursor;
      this.getDataList()
    },
    handleSizeChange(limit){//点击更改显示条数
      this.data.params.limit = limit;
      this.getDataList()
    },
    currentChange(row){
      this.rowData = row
    },
    selectionChange(row){//多选数组
      let ids=[]
      for(let i of row){
        ids.push(i.jsrcId)
      }
      this.dataArrayId = ids
      this.dataArray = row
    },
  //订单详情
    orderDetails(row){//订单详情
      this.$axios({
        method: 'get',
        url: `/hotel/shortRentContract/${row.jsrcId}`,
      }).then(res => {
        if (res.data.result) {
          let a = res.data.result.hsAddCommunity ? `${res.data.result.hsAddCommunity}\u3000` : ''
          let b = res.data.result.hsRoomType ? `${res.data.result.hsRoomType}\u3000` : ''
          let c = res.data.result.hsAddDoorplateno ? `${res.data.result.hsAddDoorplateno}` : ''
          res.data.result.address = a + b + c
          for (let i in res.data.result) {
            for (let k in this.details.params) {
              if (i == k) {
                this.details.params[k] = res.data.result[i]
              }
            }
          }
          if (res.data.result.jsrcAdditionalDescription) {
            this.details.consumption.dataConsumption = JSON.parse(res.data.result.jsrcAdditionalDescription)
            this.details.consumption.amount = res.data.result.jsrcAdditionalCost
          } else {
            this.details.consumption.dataConsumption = []
            this.details.consumption.amount = 0
          }
          if (res.data.result.jsrcAdditionalDescription) {
            this.details.followUp.dataFollowUp = JSON.parse(res.data.result.jsrcFollow)
          } else {
            this.details.followUp.dataFollowUp = []
          }
          this.details.dialogDetails = true
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    screenReceivables(){
      if (this.dataArray.length < 1) {
        this.$message.warning('请至少选择一条订单')
      } else {
        let arr = [];
        for (let i of this.dataArray) {
            if (arr.indexOf(i.jsrrChannelId) === -1) {
                arr.push(i.jsrrChannelId)
            }
        }
        if (arr.length > 1) {
          this.$message.warning('批量结清只能选择相同的协议单位使用')
        } else {
          this.receivables.dialogScreenReceivables = true
        }
      }
    },
    putReceivables(state,bool){//收款小弹窗
      this.receivables.bool = bool
      this.receivables.state = state == 0 ? 0 : state == 1 ? 1 : 2
      this.receivables.title = state == 0 ? '现金收银' : state == 1 ? '扫码收银' : '台卡收银'
      this.receivables.receivable = Math.abs(this.rowData.jsrcArrears)
      this.receivables.dialogReceivables = true
      this.$nextTick(() => {
        if (this.receivables.state == 1) {
          this.$refs.paymentCode.focus()
        } else if (this.receivables.state == 0) {
          this.$refs.netReceipts.focus()
        } else if (this.receivables.state == 2) {
          console.log(this.$refs.receivable)
          this.$refs.receivable.focus()
        }
      })
    },
    netReceiptsChange(){//实收金额改变时
      if ((Number(this.receivables.receivable) + 100) < Number(this.receivables.netReceipts)) {
        this.receivables.dialogAlert = true
      } else {
        this.receivables.dialogAlert = false
      }
      if (this.receivables.netReceipts) {
        this.receivables.giveChange = Math.round((this.receivables.netReceipts - this.receivables.receivable) * 100)/100;
      } else {
        this.receivables.giveChange = ''
      }
    },
    putReceivablesSave(state){//收款保存
      if (state == 0 && (((Number(this.receivables.receivable) + 100) < Number(this.receivables.netReceipts)) || (Number(this.receivables.receivable) > Number(this.receivables.netReceipts)))) {
        this.$message.warning('请检查实收金额！')
        return
      }
      let authCode = state == 2 ? this.receivables.paymentCode : ''
      let arr = this.receivables.bool ? this.dataArrayId : [this.rowData.jsrcId]
      let list = arr.join(',')
      let jcuId = this.receivables.bool ? this.dataArray[0].jsrrChannelId : this.rowData.jsrrChannelId
      this.$axios({
        method: 'put',
        url: '/hotel/shortRentContract/clearOnCredit',
        params: {
          jcuId: jcuId,
          jsrcIds: list,
          payType: state,
          authCode: authCode,
        }
      }).then(res => {
        this.$message.success('收款成功')
        this.receivables.dialogReceivables = false
        this.details.dialogDetails = false
        this.getDataList()
        this.cleanReceivables(state)
      }).catch(err => {
        this.isError(err,this)
      })
    },
    cleanReceivables(state){//清空收银
      // if (state == 4 || state == 5 || state == 6) {
      //   this.submitForm(['checkOut'],this)
      // } else {
      //   this.resetForm(['receivables'],this)
      // }
      this.receivables.dialogAlert = false
      this.receivables.dialogReceivables = false
      this.receivables.state = ''
      this.receivables.title = ''
      this.receivables.dialog = ''
      this.receivables.receivable = ''//应收
      this.receivables.paymentCode = ''//付款码
      this.receivables.netReceipts = ''//实收
      this.receivables.giveChange = ''//找零
    },
    changeRowData(state){//上一条下一条
      let data = this.getIndex(this.dataList,this.rowData,state,this)
      if(!data) {
        return
      }
      this.rowData = data
      this.$refs.highLightRow.setCurrentRow(data);
      this.orderDetails(data)
    },
    followUpCurrentChange(row){//选中行
      this.details.followUp.rowData = row
    },
    closeDialogDetails(){//关闭详情
      this.details.params = {
        address: '',//入住客房
        hsRoomType: '',//客房类型
        popName: '',//客户姓名
        jsrcDailyPrice: '',//日均单价
        jsrcTotalDays: '',//总住天数
        jsrcTotalPrice: '',//总住金额
        popTmpList: '',//同住人信息
        jsrcDeposit: '',//客房押金
        jsrcBeginTime: '',//入住时间
        jsrcEndTime: '',//退房时间
        jsrcState: '',//订单状态
        jsrcTypeOccupancy: '',//入住类型
      }
      this.details.consumption.dataConsumption = []
      this.details.consumption.amount = 0
      this.details.followUp.dataFollowUp = []
    },
  },
}
</script>
<style lang="scss" scoped>
  .screenInput{
    width: 125px;
  }
  .dialog >>> .el-dialog__body{
    height: 486px;
    overflow: auto;
  }
  p{
    margin: 0;
    padding-left: 10px;
    font-size: 16px;
  }
</style>